import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {concatMap, EMPTY, expand, last, Observable, of, throwError} from 'rxjs';
import {delay, map, takeWhile, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpService} from './http.service';
import {Router} from "@angular/router";

// Сервисы
import {YandexMetrikaService} from './yandex-metrika.service';
import {SettingsService} from '../../core/services/settings.service';
import {FormService} from "./form.service";
import { AppService } from '../../core/services/app.service';

// Интерфейсы
import {
  IPreapproveMortgageApplicationRequest,
  IPreapproveMortgageApplicationResponce, ISaveMortgageApplicationRequest, ISaveMortgageApplicationResponce
} from "../interface/mortgage-application.interface";
import {
  IBanksAndProduct,
  IMortgageProductsRequest, IMortgageProductsResponce,
} from "../interface/mortgage-products.interface";
import {IApplicationRequest, IApplicationResponse} from "../interface/application.interface";
import {
  IMortgageProductDetailsRequest,
  IMortgageProductDetailsResponce
} from "../interface/mortgage-product-details.interface";
import {IFormFilter} from "../interface/form-filter.interface";
import {IOffer, IOffersRequest, IOffersResponce} from "../interface/offers.interface";
import {IOfflineFormRequest} from "../interface/offline-form.interface";
import {IBankListRequest, IBankListResponce} from "../interface/bank-list";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class MortgageService extends HttpService {
  // Список офферов
  public offers: IOffer[] = [];
  // Выбранный банк
  public selectedBank!: IBanksAndProduct;
  // Выбранный оффер
  public selectedOffer!: IOffer | null;
  // Ссылка на оплату
  public paymentLink!: string;
  // Ссылка на черновик
  public draftLink!: string;
  // Ссылка на второй черновик
  public additionalPolicyDraft!: string;
  // Статус анкеты из запроса GetAppInfo
  public appStatus = '';
  // Количество попыток
  public count = 1;

  constructor(@Inject(HttpClient) protected override http: HttpClient,
              protected readonly ym: YandexMetrikaService,
              private readonly settingsService: SettingsService,
              private readonly formService: FormService,
              private readonly appService: AppService,
              private readonly router: Router) {
    super(http);
  }

  // Получаем доступные для выбора продукты
  public getMortgageProducts(): Observable<IMortgageProductsResponce> {
    const request: IMortgageProductsRequest = {
      apiKey: this.settingsService.apiKey
    }
    return this.post(environment.apiUrl + 'Dictionaries/GetMortgageProducts', request);
  }

  // Получаем cписок банков
  public getBankList(): Observable<IBankListResponce> {
    const request: IBankListRequest = {
      apiKey: this.settingsService.apiKey
    }
    return this.post(environment.apiUrl + 'Dictionaries/GetBanks', request);
  }

  // Рассчитать
  public calculate(formValue: IFormFilter): Observable<any> {
    return this.getAppInfo()
      .pipe(
        concatMap(() => this.appStatus === 'WidgetDisplayed' ? of(null) : this.appService.createApplication()),
        concatMap(() => this.preapproveMortgageApplication(formValue)),
        concatMap(() => this.getOffers()),
        expand((res) => {

          if (res.result && res.value) {
            this.filterUniqueOffers(res.value.offers);
          }

          if (res.value && res.value.allOffersReceived === false) {
            return of(res).pipe(
              delay(2000),
              concatMap(() => this.getOffers())
            );
          } else {
            return EMPTY;
          }
        }),
        last()
      );
  }

  // Рассчитать
  public calculateAprove(): Observable<any> {
    return this.getOffers()
      .pipe(
        expand((res) => {
          if (res.value && res.value.offers.length && this.selectedOffer) {
            const selectedOfferInsurerType = this.selectedOffer.insurerType;
            const offer = res.value.offers.find((offer) => offer.insurerType === selectedOfferInsurerType);

            return offer && offer.draft && offer.draft.status !== 1
              ? EMPTY
              : of(res).pipe(
                delay(2000),
                concatMap(() => this.getOffers())
              );

          } else {
            return EMPTY;
          }
        }),
        last()
      );
  }

  // Рассчитать если пришли с email
  public calculateFromEmail(formValue: IFormFilter): Observable<any> {
    return this.getAppInfo()
      .pipe(
        concatMap(() => this.appStatus === 'WidgetDisplayed' ? of(null) : this.appService.createApplication()),
        concatMap(() => this.preapproveMortgageApplication(formValue)),
        concatMap(() => this.getOffers()),
        expand((res) => {

          if (res.result && res.value) {
            this.filterUniqueOffers(res.value.offers);
          }

          if (res.value && res.value.allOffersReceived === false) {
            return of(res).pipe(
              delay(2000),
              concatMap(() => this.getOffers())
            );
          } else {
            return EMPTY;
          }
        }),
        last()
      );
  }

// Фильтруем только уникальные офферы, сохраняя их порядок
  private filterUniqueOffers(offers: IOffer[]): void {
    const offersMap = new Map(this.offers.map((offer: IOffer) => [offer.offerId, offer]));

    // Добавляем новые офферы, сохраняя их позиции
    offers.forEach((offer: IOffer, index: number) => {
      if (!offersMap.has(offer.offerId)) {
        offersMap.set(offer.offerId, offer);
        // Вставляем оффер в правильную позицию
        this.offers.splice(index, 0, offer);
      }
    });

    // Удаляем дубликаты, сохраняя первый встретившийся оффер
    const seen = new Set();
    this.offers = this.offers.filter((offer: IOffer) => {
      if (seen.has(offer.offerId)) {
        return false;
      } else {
        seen.add(offer.offerId);
        return true;
      }
    });
  }
  // Получаем данные всех брендов авто
  public getProductDetails(productId: string): Observable<IMortgageProductDetailsResponce> {
    const request: IMortgageProductDetailsRequest = {
      apiKey: this.settingsService.apiKey,
      ProductId: productId
    };
    return this.post(environment.apiUrl + 'Dictionaries/GetProductDetails', request);
  }

  // Отправялем данные фильтра
  private preapproveMortgageApplication(formValue: IFormFilter): Observable<IPreapproveMortgageApplicationResponce> {
    const request: IPreapproveMortgageApplicationRequest = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
      InsurerProductId: formValue.productId,
      CreditRemain: Number(formValue.creditRemain.toString().replace(',', '.')),
      // InsuredBirthDate: formValue.insuredBirthDate,
      InsuredBirthDate: this.getFormatDate(formValue?.insuredBirthDate),
      InsuredGender: formValue.insuredGender,
      MortgagePropertyType: formValue.mortgagePropertyType,
      MortgageObjectPararms: {
        WithoutCreditNumber: false,
        WithoutOwnership: false,
        WithWood: false,
        WithGas: false,
        WithIllegalRedevelopment: false,
      },
    }
    return this.post(environment.apiUrl + 'app/PreapproveMortgageApplication', request);
  }

  // Получаем данные офферов предварительного расчета
  public getOffers(): Observable<IOffersResponce> {
    const request: IOffersRequest = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
    }
    return this.post(environment.apiUrl + 'app/GetOffers', request);
  }

  // Выбрали оффер
  public selectOffer(offerId: string, productId: string): Observable<any> {
    return this.post(environment.apiUrl + 'app/SelectOffer', {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
      offerId: offerId
    }).pipe(
      concatMap(() => this.getProductDetails(productId)),
      map((res) => this.formService.checkValidationDynamicFields(res)),
    );
  }


  // Приводит дату в нужный формат дд.мм.гггг
  public getFormatDate(date: string): string {
    const replaceDate = date && date.toString()?.split('.').join('');
    if (replaceDate) {
      return `${replaceDate?.slice(0, 2)}.${replaceDate?.slice(2, 4)}.${replaceDate?.slice(4, 9)}`;
    } else {
      return date;
    }
  }

  // Сохарняем данные формы
  public saveMortgageApplication(formServiceValue: any, formFilterServiceValue: IFormFilter): Observable<ISaveMortgageApplicationResponce> {

    const request: ISaveMortgageApplicationRequest = this.getMortgageApplicationRequest(formServiceValue, formFilterServiceValue);

    return this.post(environment.apiUrl + 'app/SaveMortgageApplication', request).pipe(
      map((res) => {
        if (res.value && res.value.applicationId) {
          this.appService.applicationId = res.value.applicationId;
          this.router.navigate(['form'], {
            queryParamsHandling: 'merge',
            queryParams: {applicationId: this.appService.applicationId}
          });
        }
        return res;
      }),
      concatMap((res) => {
        if (!res.result) {
          throw new Error(res.error?.message || 'Failed to save mortgage application');
        }
        return of(res);
      }),
      concatMap(() => this.approveMortgageApplication()),
      delay(1000),
      concatMap(() => this.getOffers()),
      expand((res) => {
        const selectedOfferInsurerType = this.selectedOffer?.insurerType;
        const isSelectedOfferInRes = res.value?.offers.find((offer: IOffer) => offer.insurerType === selectedOfferInsurerType);

        if (!isSelectedOfferInRes) {
          return EMPTY;
        }

        const approvedOffer = res.value?.offers.find((offer: IOffer) => offer.status === 'Approved');
        if (res.value && !approvedOffer && res.value.offers.length > 0) {
          return of(res).pipe(
            delay(2000),
            concatMap(() => this.getOffers())
          );
        } else {
          return EMPTY;
        }
      }),
      last()
    );
  }

  public getMortgageApplicationRequest(formServiceValue: any, formFilterServiceValue: IFormFilter): any {
    return {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
      AddressData: {addressAsString: ""},
      policyStartDate: this.getFormatDate(formServiceValue.mortgagePolicyDate.mortgageStartDate),
      MortgageLoan: {
        MortgagePropertyType: formFilterServiceValue.mortgagePropertyType,
        BankId: formFilterServiceValue.bankId,
        CreditRate: Number(formServiceValue.mortgage.creditRate?.toString().replace(',', '.')),
        CreditRateRefusal: formServiceValue.mortgage.withRefusalRate ? Number(formServiceValue.mortgage.withRefusalRate) : undefined,
        ContractNumber: formServiceValue.mortgage.mortgageCreditNumber.replace(/\\+/g, ''),
        MortgageStartDate: this.getFormatDate(formServiceValue.mortgage.mortgageBeginDate),
        MortgageEndDate: formServiceValue.mortgage.mortgageBeginEndDate ? this.getFormatDate(formServiceValue.mortgage.mortgageBeginEndDate) : null,
        MonthlyPayment: formServiceValue.insuranceObject.monthlyPayment,
        ownershipLessThreeYears: formServiceValue.insuranceObject.ownershipLessThreeYears,
        square: Number(formServiceValue.insuranceObject.insuranceObjectSquare?.toString().replace(',', '.')),
        floorCount: Number(formServiceValue.insuranceObject.floorCount),
        roomsCount: Number(formServiceValue.insuranceObject.roomsCount),
        floor: Number(formServiceValue.insuranceObject.floor),
        builtYear: Number(formServiceValue.insuranceObject.insuranceObjectBuiltYear),
        kadastr: formServiceValue.insuranceObject.kadastr,
        insuranceBaseAmount: formServiceValue.insuranceObject.insuranceBaseAmount,
        addressSameAsInsurer: formServiceValue.insuranceObject.insuranceObjectAddressSameAsInsurer,
        AddressData: formServiceValue.insuranceObject.insuranceObjectAddressSameAsInsurer
          ? null :
          {
            addressAsString: formServiceValue.insuranceObject?.insuranceHouseNumber ? formServiceValue.insuranceObject.insuranceObjectAddress + `, кв ${formServiceValue.insuranceObject?.insuranceHouseNumber}` : formServiceValue.insuranceObject.insuranceObjectAddress,
            hasNoApartment: !formServiceValue.borrower?.insuranceHouseNumber
          },
        mortgageObjectPararms: {
          withoutCreditNumber: formServiceValue.mortgage.mortgageWithoutCreditNumber,
          withoutOwnership: false,
          withWood: formServiceValue.insuranceObject.withWood,
          withGas: formServiceValue.insuranceObject.hasGas,
          withIllegalRedevelopment: formServiceValue.insuranceObject.hasIllegalRedevelopment
        },
        loanissueaddress: this.formService.form.get('mortgage')?.get('loanIssueAddress')?.enabled ? {
          addressAsString: formServiceValue.mortgage.loanIssueAddressDaData.value,
          daDataAddress: formServiceValue.mortgage.loanIssueAddressDaData,
          hasNoApartment: false
        } : undefined
      },
      Insured: {
        firstName: formServiceValue.borrower.borrowerFirstName,
        lastName: formServiceValue.borrower.borrowerLastName,
        middleName: formServiceValue.borrower.borrowerMiddleName,
        Height: Number(formServiceValue.borrower.height) || undefined,
        Weight: Number(formServiceValue.borrower.weight) || undefined,
        LowPressure: Number(formServiceValue.borrower.lowPressure) || undefined,
        HighPressure: Number(formServiceValue.borrower.highPressure) || undefined,
        Phone: '7' + this.sanitizePhoneNumber(formServiceValue.contacts.phone),
        Email: formServiceValue.contacts.email,
        birthPlace: formServiceValue.borrower?.borrowerBirthPlace || undefined,
        profession: formServiceValue.borrower?.borrowerProfession || undefined,
        passport: {
          departmentCode: formServiceValue.borrower.borrowerPassportIssuerDadata?.code?.replace(/-/g, "") || formServiceValue.borrower.borrowerDepartmentCode?.replace(/-/g, ""),
          passportIssueDate: this.getFormatDate(formServiceValue.borrower.borrowerPassportDate),
          // passportIssuer: formServiceValue.borrower.borrowerPassportIssuer,
          passportIssuer: formServiceValue.borrower.borrowerPassportIssuerDadata?.value || formServiceValue.borrower.borrowerPassportIssuer,
          passportNumber: formServiceValue.borrower.borrowerPassportLicense.toString().replace(/\s/g, "").slice(4),
          passportSeries: formServiceValue.borrower.borrowerPassportLicense.toString().replace(/\s/g, "").slice(0, 4),
          registrationAddressData: {
            addressAsString: formServiceValue.borrower?.borrowerHouseNumber ? formServiceValue.borrower.borrowerAddress + `, кв ${formServiceValue.borrower?.borrowerHouseNumber}` : formServiceValue.borrower.borrowerAddress,
            hasNoApartment: !formServiceValue.borrower?.borrowerHouseNumber
          }
        },
        AddressData: {
          addressAsString: formServiceValue.borrower?.borrowerHouseNumber ? formServiceValue.borrower.borrowerAddress + `, кв ${formServiceValue.borrower?.borrowerHouseNumber}` : formServiceValue.borrower.borrowerAddress,
          hasNoApartment: !formServiceValue.borrower?.borrowerHouseNumber
        }
      },
      mortgageObject: formServiceValue?.insuranceObject?.mortgageObject ? {
        buildYear: Number(formServiceValue.insuranceObject.insuranceObjectBuiltYear),
        floorCount: Number(formServiceValue.insuranceObject.floorCount),
        square: Number(formServiceValue.insuranceObject.insuranceObjectSquare?.toString().replace(',', '.')),
      } : undefined
    }
  }

  // Получаем одобренные заявки
  public approveMortgageApplication(): Observable<any> {
    return this.post(environment.apiUrl + 'app/ApproveMortgageOffer', {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId
    });
  }

  // Получаем ссылку на оплату
  public getMortgagePaymentLink(offerId: string): Observable<any> {
    return this.getPaymentLink(offerId)
      .pipe(
        expand((payment) => {
          const isProcessing = payment.value?.status === 'Processing';
          if (isProcessing) {
            return of(payment).pipe(
              delay(2000),
              concatMap(() => this.getPaymentLink(offerId))
            );
          } else {
            return EMPTY;
          }
        }),
        last(),
      );
  }

  public getPaymentLink(offerId: string): Observable<any> {
    return this.post(environment.apiUrl + 'app/GetMortgagePaymentLink', {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
      offerId: offerId,
      skipNotification: this.appService.isArmApplication == true
    })
  }

  // Повторная заявка
  public repeatGetOffers(formServiceValue: IFormFilter, formFilterServiceValue: any): Observable<any> {
    return this.appService.createApplication()
      .pipe(
        concatMap(() => this.preapproveMortgageApplication(formFilterServiceValue)),
        concatMap(() => this.getOffers()),
        expand((res) => {
          if (res.value && res.value.allOffersReceived === false) {
            return of(res).pipe(
              delay(2000),
              concatMap(() => this.getOffers())
            );
          } else {
            return EMPTY;
          }
        }),
        last(),
        concatMap((offers) => {
          const selectedOfferInsurerType = this.selectedOffer?.insurerType;
          const selectedOffer = offers.value?.offers.find((offer) => offer.insurerType === selectedOfferInsurerType);
          if (selectedOffer) {
            return this.selectOffer(selectedOffer.offerId!, selectedOffer.productId)
              .pipe(
                concatMap(() => this.sendContacts()),
                concatMap(() => this.saveMortgageApplication(formServiceValue, formFilterServiceValue))
              );
          } else {
            return throwError('Selected offer is not available');
          }
        }),
        // concatMap(() => this.saveMortgageApplication(formServiceValue, formFilterServiceValue)),
      );
  }

  // Повторная заявка
  public repeatGetOffersAfterNotAprove(formServiceValue: IFormFilter, formFilterServiceValue: any): Observable<any> {
    return this.appService.createApplication()
      .pipe(
        concatMap(() => this.preapproveMortgageApplication(formFilterServiceValue)),
        concatMap(() => this.getOffers()),
        expand((res) => {
          if (res.value && res.value.allOffersReceived === false) {
            return of(res).pipe(
              delay(2000),
              concatMap(() => this.getOffers())
            );
          } else {
            return EMPTY;
          }
        }),
        last(),
        concatMap((offers) => {
          const selectedOfferInsurerType = this.selectedOffer?.insurerType;
          const selectedOffer = offers.value?.offers.find((offer) => offer.insurerType === selectedOfferInsurerType);
          if (selectedOffer) {
            return this.selectOffer(selectedOffer.offerId!, selectedOffer.productId)
              .pipe(
                concatMap(() => this.sendContacts()),
                concatMap(() => this.saveMortgageApplication(formServiceValue, formFilterServiceValue))
              );
          } else {
            return throwError('Selected offer is not available');
          }
        }),
      );
  }

  // Сохраняем данные оффлайн формы
  public saveOfflineMortgage(value: any): Observable<any> {
    return this.appService.createApplication()
      .pipe(
        concatMap(() => {
          const request: IOfflineFormRequest = {
            apiKey: this.settingsService.apiKey,
            applicationId: this.appService.applicationId,
            firstName: value.offlineFormFirstName,
            lastName: value.offlineFormLastName,
            middleName: value.offlineFormMiddleName,
            email: value.email,
            phone: '7' + this.sanitizePhoneNumber(value.phone),
            addressData: {
              addressAsString: value.city
            }
          }
          return this.post(environment.apiUrl + 'app/SaveOfflineMortgageApplication', request)
        }),
      );
  }

  // Получаем данные офферов предварительного расчета
  public sendPaymentLink(email: boolean, sms: boolean): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
      sendEmail: email,
      sendSms: sms
    }

    return this.post(environment.apiUrl + 'app/SendMortgagePaymentLink', request);
  }

  // Получаем данные анкеты с кэша
  public clickPay(): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId
    }
    return this.http.post(environment.apiUrl + 'app/SetStatusBuyButtonPressed', request)
  }

  // Отправка контактов
  public sendContacts(phone?: string, email?: string): Observable<any> {
    const emailFromForm = this.formService.form.get('contacts')?.value?.email;
    const phoneFromForm = this.formService.form.get('contacts')?.value?.phone;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.appService.applicationId,
      Phone: `7${phone ? this.sanitizePhoneNumber(phone) : this.sanitizePhoneNumber(phoneFromForm)}`,
      Email: email ? email : emailFromForm
    }

    return this.post(environment.apiUrl + 'app/SaveContacts', request);
  }

  // Получить информацию о текущей анкете
  public getAppInfo(): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId
    };
    return this.post(environment.apiUrl + 'app/GetApplicationInfo', request)
      .pipe(
        tap((response: any) => {
          if (response.result) {
            this.appStatus = response.result && response?.value?.applicationData.applicationStatus;
          }
        })
      );
  }

  public sanitizePhoneNumber(phoneNumber: string): string {
    // Remove all non-digit characters and the first digit '7'
    const sanitizedNumber = phoneNumber.replace(/[^0-97]/g, '').replace(/^7/, '');

    // Check if the sanitized number is empty or contains only non-digit characters
    if (sanitizedNumber.length === 0 || sanitizedNumber.match(/[^0-9]/g)) {
      return ''; // Return empty string if invalid
    }

    // Return the sanitized phone number
    return sanitizedNumber;
  }

}
