import { Component } from '@angular/core';
import { AppService } from '../../../../core/services/app.service';
import { MortgageService } from '../../../../shared/services/mortgage.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormService } from '../../../../shared/services/form.service';
import { finalize } from 'rxjs/operators';
import { ISaveMortgageApplicationResponce } from '../../../../shared/interface/mortgage-application.interface';
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";
import {environment} from "@environment/environment";

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss']
})
export class SuccessPageComponent {
  public isArmApplication = false;
  public clientEmail = "";
  public clientPhone = "";
  public isCopied = false;
  public isSent = false;
  public isSending = false;
  public isSendError = false;
  public optionSelected = true;
  public form: FormGroup;

  // Если мы пришли на страницу после заполнения офлайн формы
  public isOfflineStatus: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly formService: FormService,
    private readonly mortgageService: MortgageService,
    private readonly appService: AppService,
    private readonly ym: YandexMetrikaService) {

    const urlParams = new URLSearchParams(window.location.search);
    this.isOfflineStatus = urlParams.get('status') === 'offline';
    if (this.isOfflineStatus) {
      this.ym.onYandexReachGoal('OFFLINE_GOAL');
    } else {
      this.ym.onYandexReachGoal('SUCCESS_GOAL');
    }


    //this.isArmApplication = true;
    this.isArmApplication = appService.isArmApplication;
    this.clientEmail = this.formService.form.value.contacts.email;
    this.clientPhone = this.initPhoneNumber( this.formService.form.value.contacts.phone);

    this.form = this.fb.group({
      email: [true, {updateOn: 'change'}, ],
      phone: [true, {updateOn: 'change'}, ],
    });

    this.form.valueChanges.subscribe((currentValue) => {
      this.optionSelected = currentValue.email === true || currentValue.phone === true;
    });
  }

  public copyLink() {
      navigator.clipboard.writeText(this.mortgageService.paymentLink);
      this.isCopied = true;
  }

  public send() {
    this.isSending = true;
    this.isSendError = false;
    const sendEmail = this.form.value.email;
    const sendSms = this.form.value.phone;

    this.mortgageService.sendPaymentLink(sendEmail, sendSms)
        .pipe(
          finalize(() => this.isSending = false)
        )
        .subscribe({
          next: (res: ISaveMortgageApplicationResponce) => {
            this.isSent = res.result == true;
            this.isSendError = res.result != true
          },
          error: () => this.isSendError = true
        });
  }

  private initPhoneNumber(phoneNumber: string | undefined): string {
    try {
        const maskedPhone = phoneNumber?.toString();

        if (maskedPhone != null && maskedPhone.length === 10) {
          return `+7(${ maskedPhone.substr(0, 3) })${ maskedPhone.substr(3, 3) }-${ maskedPhone.substr(6, 2) }-${ maskedPhone.substr(8, 2) }`; }

        if (maskedPhone != null && maskedPhone.length === 11) {
            return `+7(${ maskedPhone.substr(1, 3) })${ maskedPhone.substr(4, 3) }-${ maskedPhone.substr(7, 2) }-${ maskedPhone.substr(9, 2) }`; }

        return maskedPhone || "";
    }
    catch {
        return "";
    }
  }

  protected readonly environment = environment;
}
