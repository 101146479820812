import {IUrlParams} from "../interface/url-params.interface";

export function getUtmData(params: IUrlParams): string {
  return JSON.stringify({
    utm_source: params.utmSource,
    utm_medium: params.utmMedium,
    utm_campaign: params.utmCampaign,
    utm_content: params.utmContent,
    utm_term: params.utmTerm,
    utm_mid: params.utmMid,
  });
}
