import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appIsDate]'
})
export class DateDirective {
    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @HostListener('keydown', ['$event'])
    onKeyDown(event: any) {
        if (
            this.navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            (event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
            (event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
            (event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
            (event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
            (event.key === 'a' && event.metaKey === true) || // Allow: Cmd+A (Mac)
            (event.key === 'c' && event.metaKey === true) || // Allow: Cmd+C (Mac)
            (event.key === 'v' && event.metaKey === true) || // Allow: Cmd+V (Mac)
            (event.key === 'x' && event.metaKey === true)    // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }

        if (event.target.selectionEnd > 0)
            return;

        const value = (event.target.value || '').replace(/\_/g, '');
        if (value.length >= 10)
            event.preventDefault();
    }
}
