<div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4">
  <div class="card-body p-2 p-sm-4">
    <div class="row card-form-block">
      <div class="col-auto card-form-block-icon">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="16" fill="#F2F4F7"/>
          <g clip-path="url(#clip0_220_5115)">
            <path d="M29 26C29 26.2652 28.8946 26.5196 28.7071 26.7071C28.5196 26.8947 28.2652 27 28 27H20C19.7348 27 19.4804 26.8947 19.2929 26.7071C19.1054 26.5196 19 26.2652 19 26C19 25.7348 19.1054 25.4805 19.2929 25.2929C19.4804 25.1054 19.7348 25 20 25H28C28.2652 25 28.5196 25.1054 28.7071 25.2929C28.8946 25.4805 29 25.7348 29 26ZM25 29H20C19.7348 29 19.4804 29.1054 19.2929 29.2929C19.1054 29.4805 19 29.7348 19 30C19 30.2652 19.1054 30.5196 19.2929 30.7071C19.4804 30.8947 19.7348 31 20 31H25C25.2652 31 25.5196 30.8947 25.7071 30.7071C25.8946 30.5196 26 30.2652 26 30C26 29.7348 25.8946 29.4805 25.7071 29.2929C25.5196 29.1054 25.2652 29 25 29ZM34 22.485V31C33.9984 32.3256 33.4711 33.5965 32.5338 34.5338C31.5964 35.4711 30.3256 35.9984 29 36H19C17.6744 35.9984 16.4036 35.4711 15.4662 34.5338C14.5289 33.5965 14.0016 32.3256 14 31V17C14.0016 15.6744 14.5289 14.4036 15.4662 13.4662C16.4036 12.5289 17.6744 12.0016 19 12H23.515C24.4346 11.9977 25.3456 12.1776 26.1952 12.5295C27.0449 12.8814 27.8163 13.3982 28.465 14.05L31.949 17.536C32.6012 18.1843 33.1184 18.9555 33.4704 19.805C33.8225 20.6545 34.0025 21.5654 34 22.485ZM27.051 15.464C26.7363 15.1592 26.3829 14.8969 26 14.684V19C26 19.2652 26.1054 19.5196 26.2929 19.7071C26.4804 19.8947 26.7348 20 27 20H31.316C31.103 19.6172 30.8404 19.2642 30.535 18.95L27.051 15.464ZM32 22.485C32 22.32 31.968 22.162 31.953 22H27C26.2044 22 25.4413 21.684 24.8787 21.1213C24.3161 20.5587 24 19.7957 24 19V14.047C23.838 14.032 23.679 14 23.515 14H19C18.2044 14 17.4413 14.3161 16.8787 14.8787C16.3161 15.4413 16 16.2044 16 17V31C16 31.7957 16.3161 32.5587 16.8787 33.1213C17.4413 33.684 18.2044 34 19 34H29C29.7956 34 30.5587 33.684 31.1213 33.1213C31.6839 32.5587 32 31.7957 32 31V22.485Z" fill="#626F80"/>
          </g>
          <defs>
            <clipPath id="clip0_220_5115">
              <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col-7 card-form-block-title p-0">
        <div class="fw-bold" [hidden]="formService.isMortgageFormComplete">Ипотека</div>
        <div class="text-secondary " [hidden]="formService.isMortgageFormComplete">Данные по вашему кредиту</div>
        <div class="fw-bold" [hidden]="!formService.isMortgageFormComplete">Ипотека</div>
        <div class="text-secondary " [hidden]="!formService.isMortgageFormComplete">Данные по вашему кредиту</div>
      </div>
      <div class="col" *ngIf="!isMortgageFormDisabled" [hidden]="!formService.isMortgageFormComplete">
        <div class="d-flex text-end justify-content-end">
          <button type="button" class="btn btn-outline-primary btn-lg d-none d-sm-block" (click)="formService.isMortgageFormComplete = !formService.isMortgageFormComplete">Редактировать</button>
          <button type="button" class="btn btn-outline-light p-2 btn-link d-block d-sm-none" (click)="formService.isMortgageFormComplete = !formService.isMortgageFormComplete">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_670_507)">
                <path d="M19.0442 0.956628C18.4776 0.390959 17.7098 0.0732422 16.9092 0.0732422C16.1086 0.0732422 15.3407 0.390959 14.7742 0.956628L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8606C0.105874 16.3663 -0.00133165 16.9084 1.24844e-05 17.4558V19.1666C1.24844e-05 19.3876 0.0878099 19.5996 0.24409 19.7559C0.40037 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64496 19.4756 5.10406 19.168 5.49001 18.78L19.0442 5.22579C19.6096 4.65929 19.9271 3.8916 19.9271 3.09121C19.9271 2.29082 19.6096 1.52313 19.0442 0.956628ZM4.31168 17.6016C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9198 2.39918 15.6883L12.685 5.40246L14.6017 7.31913L4.31168 17.6016ZM17.865 4.04746L15.7767 6.13663L13.86 4.22413L15.9492 2.13496C16.075 2.00938 16.2244 1.90983 16.3887 1.84197C16.553 1.77411 16.7291 1.73929 16.9069 1.73948C17.0847 1.73967 17.2607 1.77488 17.4249 1.8431C17.5891 1.91131 17.7382 2.01119 17.8638 2.13704C17.9893 2.26289 18.0889 2.41225 18.1568 2.57657C18.2246 2.7409 18.2594 2.91698 18.2592 3.09477C18.2591 3.27255 18.2238 3.44856 18.1556 3.61274C18.0874 3.77692 17.9875 3.92605 17.8617 4.05163L17.865 4.04746Z" fill="#98A2B3"/>
              </g>
              <defs>
                <clipPath id="clip0_670_507">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <form [formGroup]="formMortgage" novalidate [hidden]="formService.isMortgageFormComplete">

<!--      <div class="alert alert-primary mt-4 mb-4 alert-dismissible fade show" role="alert">-->
<!--        Вы можете оформить полис без данных договора, а позже вписать их в банке-->
<!--        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>-->
<!--      </div>-->

      <div class="row mt-4" [hidden]="true">
        <div class="col-12 col-md-12 col-lg-4 mb-4">
          <app-form-text-field formControlName="mortgageCreditRemain" [isReadonly]="isLoading"></app-form-text-field>
        </div>
<!--        <div class="col-12 col-md-4 col-xxl-4 mb-4">-->
<!--          <div class="form-check form-switch form-check-reverse">-->
<!--            <input class="form-check-input" type="checkbox" id="withoutCreditNumber" formControlName="mortgageWithoutCreditNumber">-->
<!--            <label class="form-check-label" for="withoutCreditNumber">Пока нет кредитного договора</label>-->
<!--          </div>-->
<!--        </div>-->
      </div>

<!--      <div class="row mt-4" *ngIf="bankSystemName === 'ozon'">-->
<!--        <div class="col-12 col-md-4 col-xxl-4 mb-4">-->
<!--         <div class="form-check form-switch form-check-reverse">-->
<!--           <input class="form-check-input" type="checkbox" id="withoutCreditNumber" formControlName="mortgageWithoutCreditNumber">-->
<!--           <label class="form-check-label" for="withoutCreditNumber">Нет номера кредитного договора</label>-->
<!--         </div>-->
<!--       </div>-->
<!--      </div>-->

      <div class="row mt-4">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <app-form-text-field formControlName="mortgageCreditNumber" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <app-form-date-field formControlName="mortgageBeginDate" [isShowCalendar]="true" [isReadonly]="isLoading"></app-form-date-field>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formMortgage.get('mortgageBeginEndDate')?.enabled">
          <app-form-date-field formControlName="mortgageBeginEndDate" [isShowCalendar]="true" [isReadonly]="isLoading"></app-form-date-field>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formMortgage.get('creditRate')?.enabled">
          <app-form-number-field formControlName="creditRate" [isReadonly]="isLoading" [popover]="'Укажите годовую ставку как в кредитном договоре'"
                                 triggers="mouseenter:mouseleave"></app-form-number-field>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-4" *ngIf="formMortgage.get('withRefusalRate')?.enabled">
          <h6>Процентная ставка по кредиту в случае отказа от страхования</h6>
          <app-form-number-field formControlName="withRefusalRate" [isReadonly]="isLoading"></app-form-number-field>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formMortgage.get('loanIssueAddress')?.enabled">
          <app-autocomplete
            formControlName="loanIssueAddress"
            [daDataControlName]="'loanIssueAddressDaData'"
            [suggestionPart]="daDataSystemName.CITY"
            [isReadonly]="isLoading"></app-autocomplete>
        </div>
      </div>

      <div class="row text-end justify-content-end">
        <div class="col text-end" *ngIf="!isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(false)">Продолжить</button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-outline-secondary btn-lg" (click)="formService.isMortgageFormComplete = !formService.isMortgageFormComplete">Отмена</button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(true)">Сохранить</button>
        </div>
      </div>
    </form>

  </div>
</div>
