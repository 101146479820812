import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {FormService} from "../../../../shared/services/form.service";
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";
import {MortgageService} from "../../../../shared/services/mortgage.service";
import {FormFilterService} from "../../../../shared/services/form-filter.service";
import { environment } from '../../../../../environments/environment';
import {DaData} from "../../../../shared/enums/da-data.enum";

@Component({
  selector: 'app-mortgage-form',
  templateUrl: './mortgage-form.component.html',
  styleUrls: ['./mortgage-form.component.scss']
})
export class MortgageFormComponent implements OnInit, OnDestroy {
  // Событие заполненной формы ипотеки
  @Output() mortgageFormComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Форма ипотеки заблочена
  @Input() isMortgageFormDisabled!: boolean;
  // Уже искали офферов, значит форма заполнена и теперь толкьо сохраняем ее без того что бы идти дальше по степам
  @Input() isApproveOffersComplete!: boolean;
  constructor(
    public readonly formService: FormService,
    private readonly ym: YandexMetrikaService,
    private readonly mortgageService: MortgageService,
    private readonly formFilterService: FormFilterService) {
  }

  public bankSystemName = environment.partnerSystemName;

  // Форма ипотеки
  public formMortgage = (this.formService.form.get('mortgage') as UntypedFormGroup);
  // Форма контактов
  public formPolicy = (this.formService.form.get('mortgagePolicyDate') as UntypedFormGroup);
  // Подписка
  private _subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.formMortgage?.get('mortgageCreditRemain')?.setValue(this.formFilterService.form.get('creditRemain')?.value)
    this.subscribeForm();
  }
  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Заполнили форму контактов
  public onSubmit(isSaveForm: boolean): void {
    this.formMortgage.markAllAsTouched();
    if (this.formMortgage.invalid) return;

    this.formService.isMortgageFormComplete = true;

    this.ym.onYandexReachGoal('MORTGAGE_GOAL');

    this.mortgageFormComplete.emit(isSaveForm);
  }

  // Подписка на изменение формы
  private subscribeForm(): void {
    const {
      mortgageBeginEndDate
    } = this.formMortgage.controls;

    const {
      mortgageEndDate
    } = this.formPolicy.controls;

    const selectedBank = this.mortgageService.selectedBank;

    //Для банков ГПБ и ВТБ дата окончания полиса = дате окончания крдеитного договора
    if (mortgageBeginEndDate.enabled && selectedBank
      && (selectedBank.bankSystemName === 'Vtb' || selectedBank.bankSystemName === 'Gazprombank')) {
      this._subscription.add(
        mortgageBeginEndDate.valueChanges.subscribe((value) => mortgageEndDate.setValue(value))
      );
    }
  }

    protected readonly daDataSystemName = DaData;
}
