<div *ngIf="!paymentLinkIsEmpty">
  <div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4 offer">
    <div class="card-body p-4">
    <span *ngFor="let badge of offer.badges"
          style="{{ badge.style }}"
          class="badge rounded-pill">
      {{badge.title}}
    </span>
      <div class="row align-items-center">
        <div class="col-12 col-sm-7 col-md-4 col-lg mb-2 mb-sm-0">
          <div class="m-0 d-flex align-items-center">
            <img src="./assets/images/partner-logo/{{offer.insurerType}}-logo.svg"
                 class="rounded d-inline-block" alt="{{offer.insurerName}}">
            <div>
              <div class="h6 m-0 p-2 pt-1 pb-0"><strong>{{ offer.insurerName }}</strong></div>
              <div class="m-0 p-2 pt-0 pb-0" *ngIf="!isApproveOffer && offer.insurerRating">
                <rating [ngModel]="offer.insurerRating" [customTemplate]="t" [max]="5" [readonly]="true"></rating>
                <ng-template #t let-index="index"
                             let-value="value">{{index < value ? '&#9733;' : '&#9733;'}}</ng-template>
                <div class="rating-value">{{offer.insurerRating}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-7 col-md-4 col-lg-3">
          <div class="row d-flex justify-content-between">
            <div class="col-auto">
              <div class="text-secondary">Остаток</div>
              <div class="fw-bold">
                {{ getCreditRemainAmount }} ₽
              </div>
            </div>
            <div class="col-auto">
              <div class="text-secondary">Цена</div>
              <div class="fw-bold">
                {{ getPreapprovePremiumAmount }} ₽
              </div>
            </div>
            <!--          <div class="col">-->
            <!--            <div class="text-secondary">Цена</div>-->
            <!--            <div class="fw-bold">-->
            <!--              <input type="text"-->
            <!--                     appIsNumeric-->
            <!--                     postfix="₽"-->
            <!--                     inputmode="numeric"-->
            <!--                     numericThousandSeparator=" "-->
            <!--                     readonly-->
            <!--                     class="form-control-plaintext p-0"-->
            <!--                     id="offerFranchisePreapprove"-->
            <!--                     [value]="offer.offerFranchisePreapprove">-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
        <div class="col-12 col-sm-5 col-md-4 col-lg-auto text-start text-sm-end mt-2 mt-sm-0">
          <div class="row">
            <div class="col" *ngIf="mortgageService.draftLink">
              <button type="button"
                      class="btn btn-default btn-lg w-100 d-flex justify-content-center align-items-center"
                      *ngIf="isApproveOffer"
                      [disabled]="isDraftLoader"
                      (click)="openDraft()">
                <ng-container *ngIf="!isDraftLoader">
                  <svg class="mx-2" width="20" height="24" viewBox="0 0 20 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8947 14.2652 15 14 15H6C5.73478 15 5.48043 14.8947 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4805 5.29289 13.2929C5.48043 13.1054 5.73478 13 6 13H14C14.2652 13 14.5196 13.1054 14.7071 13.2929C14.8946 13.4805 15 13.7348 15 14ZM11 17H6C5.73478 17 5.48043 17.1054 5.29289 17.2929C5.10536 17.4805 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8947 5.73478 19 6 19H11C11.2652 19 11.5196 18.8947 11.7071 18.7071C11.8946 18.5196 12 18.2652 12 18C12 17.7348 11.8946 17.4805 11.7071 17.2929C11.5196 17.1054 11.2652 17 11 17ZM20 10.485V19C19.9984 20.3256 19.4711 21.5965 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5965 0.00158786 20.3256 0 19V5.00002C0.00158786 3.67443 0.528882 2.40358 1.46622 1.46624C2.40356 0.528905 3.67441 0.00161091 5 2.30487e-05H9.515C10.4346 -0.00234388 11.3456 0.177611 12.1952 0.529482C13.0449 0.881354 13.8163 1.39816 14.465 2.05002L17.949 5.53602C18.6012 6.18426 19.1184 6.95548 19.4704 7.805C19.8225 8.65451 20.0025 9.56545 20 10.485ZM13.051 3.46402C12.7363 3.15918 12.3829 2.89695 12 2.68402V7.00002C12 7.26524 12.1054 7.51959 12.2929 7.70713C12.4804 7.89467 12.7348 8.00002 13 8.00002H17.316C17.103 7.61721 16.8404 7.26417 16.535 6.95002L13.051 3.46402ZM18 10.485C18 10.32 17.968 10.162 17.953 10H13C12.2044 10 11.4413 9.68395 10.8787 9.12134C10.3161 8.55873 10 7.79567 10 7.00002V2.04702C9.838 2.03202 9.679 2.00002 9.515 2.00002H5C4.20435 2.00002 3.44129 2.31609 2.87868 2.8787C2.31607 3.44131 2 4.20437 2 5.00002V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.684 4.20435 22 5 22H15C15.7956 22 16.5587 21.684 17.1213 21.1213C17.6839 20.5587 18 19.7957 18 19V10.485Z"
                      fill="var(--icon-fill-color)"/>
                  </svg>
                  Черновик
                </ng-container>
                <ng-container *ngIf="isDraftLoader">
                  <div class="spinner-border payment-loader" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                  </div>
                  Черновик
                </ng-container>
              </button>
              <button type="button"
                      class="btn btn-default btn-lg w-100 d-flex justify-content-center align-items-center"
                      *ngIf="isApproveOffer && mortgageService.additionalPolicyDraft"
                      [disabled]="isDraftLoader"
                      (click)="openAdditionalDraft()">
                <ng-container *ngIf="!isDraftLoader">
                  <svg class="mx-2" width="20" height="24" viewBox="0 0 20 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8947 14.2652 15 14 15H6C5.73478 15 5.48043 14.8947 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4805 5.29289 13.2929C5.48043 13.1054 5.73478 13 6 13H14C14.2652 13 14.5196 13.1054 14.7071 13.2929C14.8946 13.4805 15 13.7348 15 14ZM11 17H6C5.73478 17 5.48043 17.1054 5.29289 17.2929C5.10536 17.4805 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8947 5.73478 19 6 19H11C11.2652 19 11.5196 18.8947 11.7071 18.7071C11.8946 18.5196 12 18.2652 12 18C12 17.7348 11.8946 17.4805 11.7071 17.2929C11.5196 17.1054 11.2652 17 11 17ZM20 10.485V19C19.9984 20.3256 19.4711 21.5965 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5965 0.00158786 20.3256 0 19V5.00002C0.00158786 3.67443 0.528882 2.40358 1.46622 1.46624C2.40356 0.528905 3.67441 0.00161091 5 2.30487e-05H9.515C10.4346 -0.00234388 11.3456 0.177611 12.1952 0.529482C13.0449 0.881354 13.8163 1.39816 14.465 2.05002L17.949 5.53602C18.6012 6.18426 19.1184 6.95548 19.4704 7.805C19.8225 8.65451 20.0025 9.56545 20 10.485ZM13.051 3.46402C12.7363 3.15918 12.3829 2.89695 12 2.68402V7.00002C12 7.26524 12.1054 7.51959 12.2929 7.70713C12.4804 7.89467 12.7348 8.00002 13 8.00002H17.316C17.103 7.61721 16.8404 7.26417 16.535 6.95002L13.051 3.46402ZM18 10.485C18 10.32 17.968 10.162 17.953 10H13C12.2044 10 11.4413 9.68395 10.8787 9.12134C10.3161 8.55873 10 7.79567 10 7.00002V2.04702C9.838 2.03202 9.679 2.00002 9.515 2.00002H5C4.20435 2.00002 3.44129 2.31609 2.87868 2.8787C2.31607 3.44131 2 4.20437 2 5.00002V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.684 4.20435 22 5 22H15C15.7956 22 16.5587 21.684 17.1213 21.1213C17.6839 20.5587 18 19.7957 18 19V10.485Z"
                      fill="var(--icon-fill-color)"/>
                  </svg>
                  Черновик
                </ng-container>
                <ng-container *ngIf="isDraftLoader">
                  <div class="spinner-border payment-loader" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                  </div>
                  Черновик
                </ng-container>
              </button>
            </div>
            <div *ngIf="offer.promocodes.length > 0" class="col-auto">
              <button type="button" class="btn btn-outline-primary btn-lg btn-gift"
                      (click)="isShowPromocodes = !isShowPromocodes">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2689_6543)">
                    <path class="gift-icon" d="M20 7H18.262C18.8274 6.5016 19.2762 5.88488 19.5766 5.1936C19.8769 4.50232 20.0215 3.75341 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2C18.7348 2 18.4804 2.10536 18.2929 2.29289C18.1054 2.48043 18 2.73478 18 3C18 5.622 15.629 6.53 13.826 6.841C14.4915 5.66105 14.8921 4.35037 15 3C15 2.20435 14.6839 1.44129 14.1213 0.87868C13.5587 0.31607 12.7956 0 12 0C11.2044 0 10.4413 0.31607 9.87868 0.87868C9.31607 1.44129 9 2.20435 9 3C9.10791 4.35037 9.50852 5.66105 10.174 6.841C8.371 6.53 6 5.622 6 3C6 2.73478 5.89464 2.48043 5.70711 2.29289C5.51957 2.10536 5.26522 2 5 2C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3C3.97851 3.75341 4.12307 4.50232 4.42344 5.1936C4.7238 5.88488 5.1726 6.5016 5.738 7H4C2.93913 7 1.92172 7.42143 1.17157 8.17157C0.421427 8.92172 0 9.93913 0 11L0 12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14V19C2.00159 20.3256 2.52888 21.5964 3.46622 22.5338C4.40356 23.4711 5.67441 23.9984 7 24H17C18.3256 23.9984 19.5964 23.4711 20.5338 22.5338C21.4711 21.5964 21.9984 20.3256 22 19V14C22.5304 14 23.0391 13.7893 23.4142 13.4142C23.7893 13.0391 24 12.5304 24 12V11C24 9.93913 23.5786 8.92172 22.8284 8.17157C22.0783 7.42143 21.0609 7 20 7ZM12 2C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3C12.8793 4.06283 12.5387 5.08891 12 6.013C11.4613 5.08891 11.1207 4.06283 11 3C11 2.73478 11.1054 2.48043 11.2929 2.29289C11.4804 2.10536 11.7348 2 12 2ZM2 11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H11V12H2V11ZM4 19V14H11V22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19ZM20 19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7956 22 17 22H13V14H20V19ZM13 12V9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V12H13Z"/>
                  </g>
                </svg>

              </button>
            </div>
            <div [class]="isDraftLoader ? 'col-auto' : 'col'">
              <button type="button" class="btn btn-primary btn-lg w-100" *ngIf="!isApproveOffer"
                      (click)="selectOffer(offer)">Выбрать
              </button>
              <button type="button"
                      class="btn btn-primary btn-lg w-100 d-flex justify-content-center align-items-center"
                      *ngIf="isApproveOffer"
                      [disabled]="isPaymentCountdownStarted || !agreement"
                      (click)="payment()">
                <ng-container *ngIf="!isPaymentCountdownStarted">
                  Оплатить
                </ng-container>
                <ng-container *ngIf="isPaymentCountdownStarted">
                  <div class="spinner-border payment-loader" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                  </div>
                  {{ paymentCountdown }}
                </ng-container>
              </button>
            </div>
            <!--          <div class="col-auto">-->
            <!--            <button type="button" class="btn btn-light btn-info btn-lg">-->
            <!--              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0022 0.478516C10.5256 0.478516 8.1047 1.2129 6.04551 2.58881C3.98632 3.96472 2.38138 5.92034 1.43363 8.20839C0.485894 10.4964 0.237922 13.0141 0.721076 15.4431C1.20423 17.8721 2.39681 20.1033 4.14801 21.8545C5.8992 23.6057 8.13036 24.7982 10.5593 25.2814C12.9883 25.7645 15.506 25.5166 17.7941 24.5688C20.0821 23.6211 22.0378 22.0161 23.4137 19.957C24.7896 17.8978 25.524 15.4768 25.524 13.0003C25.5204 9.68038 24.2 6.49752 21.8525 4.15001C19.505 1.80251 16.3221 0.482106 13.0022 0.478516ZM13.0022 23.435C10.9384 23.435 8.92095 22.823 7.20496 21.6765C5.48897 20.5299 4.15152 18.9002 3.36173 16.9935C2.57195 15.0868 2.3653 12.9887 2.76793 10.9645C3.17056 8.94038 4.16438 7.08108 5.62371 5.62175C7.08304 4.16242 8.94234 3.1686 10.9665 2.76597C12.9906 2.36335 15.0887 2.56999 16.9954 3.35977C18.9021 4.14956 20.5318 5.48701 21.6784 7.203C22.825 8.91899 23.437 10.9365 23.437 13.0003C23.434 15.7668 22.3336 18.4192 20.3774 20.3754C18.4211 22.3316 15.7688 23.432 13.0022 23.435ZM13.0022 8.82637C13.8667 8.82637 14.5675 8.1256 14.5675 7.26115C14.5675 6.3967 13.8667 5.69593 13.0022 5.69593C12.1378 5.69593 11.437 6.3967 11.437 7.26115C11.437 8.1256 12.1378 8.82637 13.0022 8.82637ZM14.0457 11.9568C14.0457 11.3805 13.5785 10.9133 13.0022 10.9133C12.4259 10.9133 11.9588 11.3805 11.9588 11.9568V19.2612C11.9588 19.8374 12.4259 20.3046 13.0022 20.3046C13.5785 20.3046 14.0457 19.8374 14.0457 19.2612L14.0457 11.9568Z" fill="#98A2B3"/>-->
            <!--              </svg>-->
            <!--            </button>-->
            <!--          </div>-->
          </div>
        </div>

        <div *ngIf="isShowPromocodes" class="description-list">
          <div class="row description-item mt-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0" *ngFor="let item of offer.promocodes">
              <div class="gift" [style.background-color]="item.color">
                <div class="row d-flex flex-column justify-content-between">
                  <div class="col">
                    <img [src]="item.imageSrc" alt="">
                  </div>
                  <div class="col-auto">
                    <div class="gift-title">
                      {{item.promocodeTitle}}
                    </div>
                    <div class="gift-sub">
                      {{item.promocodeSubtitle}}
                    </div>
                    <div class="gift-desc">
                      {{item.promocodeDescription}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-ptimary gift-alert border-0 mb-0 mt-0 mt-sm-3" role="alert">
            <div>Промокоды с подарками будут отправлены вам на почту после оформления полиса</div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="alert alert-success mt-4" role="alert"
       *ngIf="isApproveOffer&& !isPaymentCountdownStarted && partnerSystemName === 'sber'">
    Полис поступит на почту в течение 20 минут после оплаты. Загрузите полис и документ, подтверждающий оплату, на сайте
    ДомКлик или принесите в офис банка.
  </div>

  <ng-container *ngIf="isApproveOffer && !isPaymentCountdownStarted">
    <div class="form-check mt-4" *ngFor="let item of formService.agreements">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked [(ngModel)]="agreement">
      <label class="form-check-label" for="flexCheckChecked">
        <div [innerHTML]="item"></div>
      </label>
    </div>
  </ng-container>
</div>

<!--<alert type="warning" class="d-block mt-4">-->
<alert type="warning" *ngIf="paymentLinkIsEmpty && !isPaymentCountdownStarted" class="d-block mt-4">
  <strong>Полис не подтвержден страховой компанией</strong>
  <p>Не удалось получить ссылку на оплату от страховой, повторите позднее</p>
  <button type="button" class="btn btn-primary btn-lg"
          routerLink="/" queryParamsHandling="merge">Выбрать другое предложение
  </button>
</alert>
