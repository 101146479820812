<ng-container
  *ngIf="isArmApplication == true; then armTemplate; else environment.partnerSystemName === 'ozon' ?  ozonInfoTemplate : infoTemplate">
</ng-container>

<ng-template #infoTemplate>
  <div class="row justify-content-center success-page mt-4">
    <div class="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
      <div class="card shadow-sm border-light text-center">
        <div class="success-icon">
          <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="72" height="72" rx="24"/>
            <path d="M48.5043 26.3588L32.3835 43.2056C32.2751 43.3193 32.1463 43.4095 32.0045 43.4711C31.8626 43.5327 31.7106 43.5644 31.557 43.5644C31.4034 43.5644 31.2513 43.5327 31.1095 43.4711C30.9676 43.4095 30.8388 43.3193 30.7305 43.2056L24.4963 36.6841C24.3879 36.5704 24.2591 36.4802 24.1173 36.4186C23.9755 36.357 23.8234 36.3253 23.6698 36.3253C23.5162 36.3253 23.3642 36.357 23.2223 36.4186C23.0805 36.4802 22.9517 36.5704 22.8433 36.6841C22.7345 36.7974 22.6482 36.932 22.5892 37.0802C22.5303 37.2285 22.5 37.3874 22.5 37.5479C22.5 37.7084 22.5303 37.8673 22.5892 38.0156C22.6482 38.1638 22.7345 38.2984 22.8433 38.4117L29.0798 44.9283C29.7376 45.6146 30.6292 46 31.5587 46C32.4882 46 33.3798 45.6146 34.0377 44.9283L50.1573 28.0852C50.2659 27.9719 50.3521 27.8374 50.4109 27.6893C50.4697 27.5411 50.5 27.3823 50.5 27.222C50.5 27.0616 50.4697 26.9028 50.4109 26.7547C50.3521 26.6066 50.2659 26.472 50.1573 26.3588C50.0489 26.2451 49.9201 26.1548 49.7783 26.0933C49.6364 26.0317 49.4843 26 49.3308 26C49.1772 26 49.0251 26.0317 48.8833 26.0933C48.7414 26.1548 48.6126 26.2451 48.5043 26.3588Z" fill="white"/>
          </svg>
        </div>

        <h2 class="success-title">
          Спасибо за обращение!
        </h2>

        <div class="success-post-title">
          Мы работаем 10:00 - 18:00 по Московскому времени.<br class="d-none d-sm-block"> В ближайшее время мы свяжемся с вами для оформления полиса
        </div>

        <div class="success-action">
          <button class="btn btn-outline-primary" routerLink="/" queryParamsHandling="merge">Вернуться на главную</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ozonInfoTemplate>
  <div *ngIf="isOfflineStatus" class="row justify-content-center mt-4 success-ozon">
    <div class="col-12 col-sm-10 col-lg-6 col-xl-4 p-0">
      <div class="card shadow-sm border-light rounded-5 text-center">
        <div class="row"></div>
        <div class="row p-2 p-sm-4">
          <div class="col-12">
            <img src="assets/landingFiles/success.svg" alt="Success">
          </div>
          <h3 class="fw-bold">Ваша заявка отправлена</h3>
          <div class="text-secondary mb-2">Спасибо за обращение. Мы работаем с 8:00&nbsp;до&nbsp;18:00 по Московскому времени. В ближайшее время мы свяжемся с вами для оформления полиса.</div>
          <div class="success-action">
            <button class="btn btn-primary w-100" routerLink="/" queryParamsHandling="merge">Вернуться а главную</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isOfflineStatus" class="row justify-content-center mt-4 success-ozon">
    <div class="col-12 col-sm-10 col-lg-6 col-xl-4 p-0">
      <div class="card shadow-sm border-light rounded-5 text-center">
        <div class="row"></div>
        <div class="row p-2 p-sm-4">
          <div class="col-12">
            <img src="assets/landingFiles/success.svg" alt="Success">
          </div>
          <h3 class="fw-bold">Отправили полис на email</h3>
          <div class="text-secondary mb-2">Письмо придёт в течение 20 минут.<br>Если его не будет, напишите нам</div>
          <div class="card mt-2 border-light h-auto shadow-sm rounded-5 ozon-card">
            <div class="card-body p-2">
              <div class="row align-items-start card-form-block">
                <div class="col-auto card-form-block-icon">
                  <img src="assets/landingFiles/kopeck.svg" alt="Kopeck">
                </div>
                <div class="col-9 p-0 text-start">
                  <div>При оплате картой Ozon Банка вернём до 10% от стоимости страховки кешбэком на карту<br> в течение 90 дней после<br> вступления полиса в силу</div>
                </div>
              </div>
            </div>
          </div>
          <div class="success-action">
            <button class="btn btn-primary w-100" routerLink="/" queryParamsHandling="merge">Понятно</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #armTemplate>
  <div class="row justify-content-center success-page mt-4">
    <div class="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
      <div class="card shadow-sm border-light text-center">
        <div class="success-icon">
          <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="72" height="72" rx="24"/>
            <path d="M48.5043 26.3588L32.3835 43.2056C32.2751 43.3193 32.1463 43.4095 32.0045 43.4711C31.8626 43.5327 31.7106 43.5644 31.557 43.5644C31.4034 43.5644 31.2513 43.5327 31.1095 43.4711C30.9676 43.4095 30.8388 43.3193 30.7305 43.2056L24.4963 36.6841C24.3879 36.5704 24.2591 36.4802 24.1173 36.4186C23.9755 36.357 23.8234 36.3253 23.6698 36.3253C23.5162 36.3253 23.3642 36.357 23.2223 36.4186C23.0805 36.4802 22.9517 36.5704 22.8433 36.6841C22.7345 36.7974 22.6482 36.932 22.5892 37.0802C22.5303 37.2285 22.5 37.3874 22.5 37.5479C22.5 37.7084 22.5303 37.8673 22.5892 38.0156C22.6482 38.1638 22.7345 38.2984 22.8433 38.4117L29.0798 44.9283C29.7376 45.6146 30.6292 46 31.5587 46C32.4882 46 33.3798 45.6146 34.0377 44.9283L50.1573 28.0852C50.2659 27.9719 50.3521 27.8374 50.4109 27.6893C50.4697 27.5411 50.5 27.3823 50.5 27.222C50.5 27.0616 50.4697 26.9028 50.4109 26.7547C50.3521 26.6066 50.2659 26.472 50.1573 26.3588C50.0489 26.2451 49.9201 26.1548 49.7783 26.0933C49.6364 26.0317 49.4843 26 49.3308 26C49.1772 26 49.0251 26.0317 48.8833 26.0933C48.7414 26.1548 48.6126 26.2451 48.5043 26.3588Z" fill="white"/>
          </svg>
        </div>

        <h2 class="success-title">
          Ссылка на оплату успешно сформирована!
        </h2>

        <div class="success-action">
          <button class="btn btn-outline-primary" (click)="copyLink()">Копировать</button>
        </div>

        <alert type="info" *ngIf="isCopied" class="d-block mt-4">
          Ссылка скопирована в буфер обмена
        </alert>

        <div>
          <form [formGroup]="form" novalidate>

            <div class="row mt-4">
              <div class="col-12 col-md-12 col-lg-9 ">
                <div class="form-check form-switch form-check-reverse">
                  <input class="form-check-input" type="checkbox" id="sendMail"
                         formControlName="email">
                  <label class="form-check-label" for="sendMail">Отправить на электронную почту {{ clientEmail }}</label>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md-12 col-lg-9 ">
                <div class="form-check form-switch form-check-reverse">
                  <input class="form-check-input" type="checkbox" id="sendSms"
                         formControlName="phone">
                  <label class="form-check-label" for="sendSms">Отправить смс на номер телефона {{ clientPhone }}</label>
                </div>
              </div>
            </div>

          </form>

          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-outline-primary d-flex justify-content-center align-items-center"
              [disabled]="(isSent || isSending || !optionSelected)"
              style="column-gap: 10px;"
              (click)="send()">

              <ng-container *ngIf="!isSending">
                Отправить
              </ng-container>
              <ng-container *ngIf="isSending">
                Отправка
                <div class="spinner-border payment-loader" role="status">
                  <span class="visually-hidden">Отправка </span>
                </div>
              </ng-container>

            </button>
          </div>

          <alert type="info" *ngIf="isSent" class="d-block mt-4">
            Ссылка отправлена
          </alert>

          <alert type="warning" *ngIf="isSendError" class="d-block mt-4">
            При отправке ссылки произошла ошибка. Ссылка не была отправлена
          </alert>

        </div>

      </div>
    </div>
  </div>
</ng-template>
