import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from "../../../../shared/services/navigation.service";
import {PermitRouteService} from "../../../../core/services/permit-route.service";
import {FormService} from "../../../../shared/services/form.service";
import {Observable, Subscription, of, throwError} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import { filter } from 'rxjs/operators';

// Сервисы
import {MortgageService} from "../../../../shared/services/mortgage.service";
import {FormFilterService} from "../../../../shared/services/form-filter.service";
import { AppService } from '../../../../core/services/app.service';
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";

// Перечисления
import {ROUTES} from "../../../../shared/enums/routes.enum";

// Интерфейсы
import {ISaveMortgageApplicationResponce} from "../../../../shared/interface/mortgage-application.interface";
import {IOffer} from "../../../../shared/interface/offers.interface";
import {catchError, finalize} from "rxjs/operators";
import {FormGroup} from "@angular/forms";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private readonly navigationService: NavigationService,
              private readonly permitRouteService: PermitRouteService,
              public readonly formService: FormService,
              public readonly formFilterService: FormFilterService,
              public readonly mortgageService: MortgageService,
              private readonly appService: AppService,
              private readonly ym: YandexMetrikaService,
              private readonly router: Router) {
    if (!this.permitRouteService.isPermitRoute(ROUTES.Form)) {
      this.navigateToBack(new Event('click'));
    }
  }

  public bankSystemName = environment.partnerSystemName;

  // Индикатор загрузки
  public isLoading = false;
  public isLoadingFormCache = false;
  // Подписка
  private subscriptions: Subscription[] = [];
  // Форма
  public form = this.formService.form;

  // Получили офферов
  public isApproveOffersComplete: boolean = false;
  // Офферы
  public offers: any = [];
  // Ошибка
  public isError: boolean = false;
  // Ищем апрув офферы
  public isApproveOffer: boolean = true;
  // Документ
  public agreement = true;

  private checkArmApplication(): Observable<void> {
    if (!this.appService.isArmApplication)
      return of();

      return this.appService.armApplication$;
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event: any) => event.routerEvent instanceof NavigationEnd))
        .subscribe((event: any) => window.scrollTo({ top: 0, behavior: 'smooth' }))
    );

    this.ym.onYandexReachGoal('FORM_GOAL');

    if (this.appService.isArmApplication)
      this.getFormDataFromARM();
    else {
      // Сохраняем форму в локал сторедж только если не пришли в форму по ссылке из почты
      const urlParams = this.appService.getUrlParams();

      if (urlParams.offerId === '0') {
        this.getFormDataFromLocalStorage();
      }
    }

    this.showDefaultForm();
  }

  // После инициализации контента
  public ngAfterViewInit(): void {

  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.mortgageService.selectedOffer = null;
    this.mortgageService.offers = [];
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.formService.isContactFormComplete = false;
    this.formService.isBorrowerFormComplete = true;
    this.formService.isMortgageFormComplete = true;
    this.formService.isPolicyFormComplete = true;
    this.formService.isInsuranceFormComplete = true;
    this.formService.isBorrowerFormDisabled = true;
    this.formService.isMortgageFormDisabled = true;
    this.formService.isPolicyFormDisabled = true;
    this.formService.isInsuranceFormDisabled = true;
  }
  // --------------------------------------------------------------------------

  public navigateToBack(event: Event): void {
    event.preventDefault();
    this.navigationService.navigate('/');
  }

  // Заполнили форму контактов
  public contactFormComplete(isSaveForm: boolean): void {
    this.mortgageService.count = 1;
    if (isSaveForm) {
      this.triggerNewGetOffers();
    } else {
      if (this.formService.isBorrowerFormDisabled) {
        if (this.formService.isContactFormComplete) {
          this.formService.isBorrowerFormComplete = false;
        } else {
          this.formService.isBorrowerFormComplete = true;
        }
      }
    }
    // Теперь форма заемщика не заблочена
    this.formService.isBorrowerFormDisabled = false;
  }

  // Заполнили форму контактов
  public borrowerFormComplete(isSaveForm: boolean): void {
    this.mortgageService.count = 1;
    // this.formService.isBorrowerFormComplete = !this.formService.isBorrowerFormComplete;
    if (isSaveForm) {
      this.inspectFormValid();
    } else {
      if (this.formService.isMortgageFormDisabled) {
        if (this.formService.isBorrowerFormComplete) {
          this.formService.isMortgageFormComplete = false;
        } else {
          this.formService.isMortgageFormComplete = true;
        }
      }
    }
    // Теперь форма ипотеки не заблочена
    this.formService.isMortgageFormDisabled = false;
  }

  // Заполнили форму ипотеки
  public mortgageFormComplete(isSaveForm: boolean): void {
    this.mortgageService.count = 1;
    // this.formService.isMortgageFormComplete = !this.formService.isMortgageFormComplete;
    if (isSaveForm) {
      this.triggerNewGetOffers();
    } else {
      if (this.formService.isPolicyFormDisabled) {
        if (this.formService.isMortgageFormComplete) {
          this.formService.isPolicyFormComplete = false;
        } else {
          this.formService.isPolicyFormComplete = true;
        }
      }
    }
    // Теперь форма даты полиса не заблочена
    this.formService.isPolicyFormDisabled = false;
  }

  // Заполнили форму ипотеки
  public policyFormComplete(isSaveForm: boolean): void {
    this.mortgageService.count = 1;
    // this.formService.isPolicyFormComplete = !this.formService.isPolicyFormComplete;
    if (isSaveForm) {
      this.triggerNewGetOffers();
    } else {
      if (this.formService.isInsuranceFormDisabled) {
        if (this.formService.isPolicyFormComplete) {
          this.formService.isInsuranceFormComplete = false;
        } else {
          this.formService.isInsuranceFormComplete = true;
        }
      }
    }
    // Теперь форма страхователя не заблочена
    this.formService.isInsuranceFormDisabled = false;
  }

  // Заполнили форму страхователя
  public insuranceFormComplete(isGetOffers: boolean): void {
    this.mortgageService.count = 1;
    this.formService.isInsuranceFormComplete = !this.formService.isInsuranceFormComplete;

    if (isGetOffers && this.formService.isInsuranceFormComplete) {
      this.getOffers();

      this.ym.onYandexReachGoal('FORM_FINISH_GOAL');

      this.isApproveOffersComplete = true;
    }
  }

  // Получить предложения
  public getOffers(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.isError = false;
    this.isLoading = true;
    const formServiceValue = this.formService.form.value;
    const formFilterServiceValue = this.formFilterService.form.value;
    if (this.isApproveOffersComplete) {
      this.subscriptions.push(
        this.mortgageService.repeatGetOffers(formServiceValue, formFilterServiceValue)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe((res) => {
            const approveOffer = res.value?.offers.find((offer: IOffer) => offer.status === 'Approved');
            if (approveOffer) {
              this.isApproveOffer = true;
              this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'Approved');
            } else {
              this.isApproveOffer = false;
              this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'PreApproved');

            }
          })
      );
    } else {
      this.subscriptions.push(
        this.mortgageService.saveMortgageApplication(formServiceValue, formFilterServiceValue)
          .pipe(
            finalize(() => this.isLoading = false),
            catchError((error: any) => {
              this.isError = true;
              return throwError(error);
            })
          )
          .subscribe((res: ISaveMortgageApplicationResponce) => {
            const approveOffer = res.value?.offers.find((offer: IOffer) => offer.status === 'Approved');
            if (approveOffer) {
              this.isApproveOffer = true;
              this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'Approved');
            } else {
              this.isApproveOffer = false;
              this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'PreApproved');

              // Повторный запрос
              // if (this.mortgageService.count === 1) {
              //   this.mortgageService.count + 1;
              //   this.getOffers();
              // }

            }
          })
      );
    }

  }

  // Проверяем валидность формы и переводим клиента на шаг где форма невалидна
  public inspectFormValid(): void {
    if (this.form.invalid) {
      // Если форма с данными объекта страхования невалидна, то перед поиском офферов переходим на эту форму
      if (this.form.get('insuranceObject')?.invalid) {
        this.borrowerFormComplete(false);
        this.formService.isInsuranceFormComplete = false;
        this.formService.form.get('insuranceObject')?.markAllAsTouched();
      }
    } else {
      this.triggerNewGetOffers();
    }

  }

  // Новый поиск офферов после того как предыдущий не овтетил и выбрали нового
  public triggerNewGetOffers(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.isLoading = true;
    const formServiceValue = this.formService.form.value;
    const formFilterServiceValue = this.formFilterService.form.value;
    this.subscriptions.push(
      this.mortgageService.repeatGetOffersAfterNotAprove(formServiceValue, formFilterServiceValue)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((res: ISaveMortgageApplicationResponce) => {
          const approveOffer = res.value?.offers.find((offer: IOffer) => offer.status === 'Approved');
          if (approveOffer) {
            this.isApproveOffer = true;
            this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'Approved');
          } else {
            this.isApproveOffer = false;
            this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'PreApproved')
          }
        })
    );
  }

  // Проверяем даныне формы в локальном хранилище
  private getFormDataFromLocalStorage(): void {
    // Получаем данные из локального хранилища, если они есть
    const formData = this.formService.getFormFromLocalStorage();

    if (formData) {
      this.form.patchValue(formData);
      // (this.form.get('borrower') as FormGroup)?.get('borrowerPassportIssuer')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.value);
      // (this.form.get('borrower') as FormGroup)?.get('borrowerDepartmentCode')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.code);
    }

    // Слушаем изменения значений в форме и сохраняем их в локальное хранилище
    this.form.valueChanges.subscribe((value) => {
      this.formService.saveFormToLocalStorage();
    });
  }

  // Проверяем даныне формы из АРМ
  private getFormDataFromARM(): void {
    // Получаем данные из АРМ, если они есть
    const formData = this.appService.armApplication?.application;

    if (formData) {
      this.form.patchValue(formData);
      // (this.form.get('borrower') as FormGroup)?.get('borrowerPassportIssuer')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.value);
      // (this.form.get('borrower') as FormGroup)?.get('borrowerDepartmentCode')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.code);
    }
  }

  // Отображаем по умолчанию форму
  public showDefaultForm(): void {
    if (this.formService.isContactFormComplete
      && this.formService.isBorrowerFormComplete
      && this.formService.isMortgageFormComplete
      && this.formService.isPolicyFormComplete
      && this.formService.isInsuranceFormComplete) {
      this.formService.isInsuranceFormComplete = false;
    }
  }

}
