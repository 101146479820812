import {inject, Injectable} from '@angular/core';
import {environment} from "@environment/environment";
import {IApplicationRequest} from "../interface/application.interface";
import {getUtmData} from "../functions/utmData";
import {CalendarHelper} from "../helpers/calendarHelper";
import {AppService} from "../../core/services/app.service";
import {SettingsService} from "../../core/services/settings.service";

declare global {
    interface Window {
        ym: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export abstract class YandexMetrikaService {
  private appService = inject(AppService);
  private settingsService = inject(SettingsService);

    constructor() {
        this.ym = window.ym;
    }

    // Яндекс метрика
    private readonly ym;

  // Отправка события в яндекс метрику
  public onYandexReachGoal(ymTarget: string, goalParams?: any): void {
    const url: URL = new URL(window.location.href);
    const urlParams = this.appService.getUrlParams();
    const request: IApplicationRequest = {
      apiKey: this.settingsService.apiKey,
      productType: 'Mortgage',
      clientId: localStorage.getItem('clientId')!,
      channelType: "Widget",
      SourceUrl: url.href,
      source: urlParams.src,
      platformId: urlParams.platformId,
      webMasterID: urlParams.webMasterID,
      cpaClientUid: urlParams.CpaClientUid,
      cpaClientUid2: urlParams.CpaClientUid2,
      utmSource: urlParams.utmSource,
      utmMedium: urlParams.utmMedium,
      utm: getUtmData(urlParams),
      loyalty: urlParams.loyalty,
      localTime: CalendarHelper.getLocalDateTimeString(),
    }

    goalParams = {...goalParams, ...request};

    if (window.ym) {
      // Отправка цели с параметрами
      window.ym(environment.yandexMetrika, 'reachGoal', ymTarget, goalParams);
      if (environment.yandexMetrikaPartner) {
        window.ym(environment.yandexMetrikaPartner, 'reachGoal', ymTarget, goalParams);
      }
    }
    // this.metrika.fireEvent(ymTarget);
  }

}
