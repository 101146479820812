import {Injectable} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// Хелперы

// Перечисления
import {MortgageObjectsEnum} from "../enums/mortgage-objects.enum";

// Интерфейсы
import {IMortgageObjectItem} from "../interface/mortgage-application.interface";
import {IBanksAndProduct} from "../interface/mortgage-products.interface";
import {IBankListResponce} from "../interface/bank-list";

@Injectable({
  providedIn: 'root'
})
export class FormFilterService {

  constructor(private readonly fb: UntypedFormBuilder) {
     this.initMortgageObjectsList();
  }

  // Форма
  public form: UntypedFormGroup = this.fb.group({
    productId: new FormControl(''),
    mortgagePropertyType: new FormControl('Flat', [Validators.required]),
    bankId: new FormControl('', [Validators.required]),
    creditRemain: new FormControl(null, [Validators.required]),
    insuredBirthDate: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'submit'
    }),
    insuredGender: new FormControl('Male', [Validators.required]),
    product: new FormControl('LifeAndProperty', [Validators.required]),
  });
  // Список объектов страхования ипотеки
  public mortgageObjects!: IMortgageObjectItem[];
  // Список всех продуктов
  public allMortgageProductList: IBanksAndProduct[] = [];
  // Список банков
  public bankList: any[] = []
  // Список выбора пола
  public genderList: any[] = [
    {
      id: 'Male',
      value: 'Мужской'
    },
    {
      id: 'Female',
      value: 'Женский'
    }
  ];

  public validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsUntouched({onlySelf: true});
        control.markAsPristine();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // Инициализация объектов страхования ипотеки
  private initMortgageObjectsList(): void {
    this.mortgageObjects = Object.entries(MortgageObjectsEnum).map(([key, value]) => ({
      id: key,
      value: value,
    }));
  }

  // Уникальные значения для списка доступных продуктов
  public getUniqueInsurerTypeBankProducts(bankProducts: IBanksAndProduct[]): IBanksAndProduct[] {
    const uniqueInsurerTypes: string[] = [];

    // Фильтруем массив банковских продуктов, чтобы оставить только уникальные InsurerType
    const uniqueBankProducts: IBanksAndProduct[] = bankProducts.filter((product) => {
      if (uniqueInsurerTypes.includes(product.bank)) {
        return false;
      } else {
        uniqueInsurerTypes.push(product.bank);
        return true;
      }
    });

    return uniqueBankProducts;
  }


  // Сохраняем фильтр
  public saveFilterFormToLocalStorage(): void {
    localStorage.setItem('mortgageFilterValue', JSON.stringify(this.form.value));
  }

  public getFilterFromLocalStorage(): any {

    const filterDataFromLocalStorage = localStorage.getItem('mortgageFilterValue');
    if (filterDataFromLocalStorage) {
      const filterData = JSON.parse(filterDataFromLocalStorage);
      return filterData;
    }

  }

  // Применяем данные в форму, взятые из кэша
  public setFilterFormDataFromCache(formData: any): void {
    const {
      bankId,
      creditRemain,
      insuredBirthDate,
      insuredGender,
      product,
      productId,
      mortgagePropertyType
    } = this.form.controls;

    creditRemain.setValue(formData?.creditRemain?.toString()?.replace('.', ','));
    insuredBirthDate.setValue(formData?.insured?.birthDate);
    bankId.setValue(formData?.mortgageLoan?.bankId);
    mortgagePropertyType.setValue(formData?.mortgageLoan?.mortgagePropertyType);
    productId.setValue(formData?.insurerProductId);
  }
}
