import {Injectable} from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
// import moment from 'moment-mini';

// Хелперы
import {subControl} from '../helpers';

// Перечисления
import {CarDocumentTypesEnum} from '../enums/car-document-types.enum';
import {CarIdentificatorsEnum} from '../enums/car-identificators.enum';

// Интерфейсы
import {IPropertyControls} from '../interface/property-controls.interface';

// Данные контролов
import * as PropertyControls from '../../../assets/data/property-controls.json';
import {IFields, IMortgageProductDetailsResponce} from "../interface/mortgage-product-details.interface";
import moment from "moment-mini";

@Injectable({
  providedIn: 'root'
})
export class FormService {

  // Форма
  public form: UntypedFormGroup;
  public subscriptions: Subscription[] = [];
  public propertyControls: IPropertyControls = PropertyControls as unknown as IPropertyControls;

  // Массив документов
  public agreements: string[] = []

  // Форма контактов заполнена и валидна
  public isContactFormComplete = false;

  // Форма заемщика заполнена и валидна
  public isBorrowerFormComplete = true;

  // Форма ипотеки заполнена и валидна
  public isMortgageFormComplete = true;

  // Форма даты полиса заполнена и валидна
  public isPolicyFormComplete = true;

  // Форма страхователя заполнена и валидна
  public isInsuranceFormComplete = true;

  // Форма заемщика неактивна
  public isBorrowerFormDisabled = true;

  // Форма ипотеки неактивна
  public isMortgageFormDisabled = true;

  // Форма даты полиса неактивна
  public isPolicyFormDisabled = true;

  // Форма страхователя неактивна
  public isInsuranceFormDisabled = true;

  // Скрываем если заполнили автоматически
  public insuranceObjectSquareIsHidden = false;
  public insuranceBaseAmountIsHidden = false;
  public kadastrIsHidden = false;

  // Максимальное значение даты начала действия полиса
  public policyStartDateMaxOffsetDaysDate: null | Date = null;

  constructor(private readonly fb: UntypedFormBuilder) {

    this.form = this.fb.group({
      applicationId: [null, [Validators.required]],
      privacy: [true, [Validators.requiredTrue]],
      borrower: this.fb.group({
        borrowerFirstName: [''],
        borrowerLastName: [''],
        borrowerMiddleName: [''],
        borrowerBirthDate: [null],
        borrowerPassportLicense: [null, ],
        borrowerPassportDate: [null],
        borrowerAddress: [''],
        borrowerAddressDaData: [null],
        borrowerAddressFullDaData: [null],
        borrowerHouseNumber: [null, []],
        borrowerGender: ['Male'],
        borrowerDepartmentCode: [null],
        borrowerPassportIssuer: [''],
        borrowerPassportIssuerDadata: [null],
        borrowerBirthPlace: [null],
        borrowerProfession: [null],
        highPressure: [120],
        lowPressure: [80],
        weight: [85],
        height: [180],
      }),
      mortgage: this.fb.group({
        mortgageCreditRemain: [null, [Validators.required]],
        mortgageWithoutCreditNumber: [null],
        mortgageCreditNumber: [null, [Validators.required]],
        mortgageBeginDate: [null, [Validators.required]],
        mortgageBeginEndDate: [null, [Validators.required]],
        creditRate: [9],
        withRefusalRate: [null],
        loanIssueAddress: [null],
        loanIssueAddressDaData: [null],
      }),
      mortgagePolicyDate: this.fb.group({
        mortgageStartDate: [moment().add(1, 'days').format('DD.MM.YYYY'), [Validators.required]],
        mortgageEndDate: [null, [Validators.required]]
      }),
      insuranceObject: this.fb.group({
        insuranceObjectAddressSameAsInsurer: [true],
        insuranceObjectAddress: ['', [Validators.required]],
        insuranceObjectAddressDaData: [null],
        insuranceObjectFullAddressDaData: [null],
        insuranceHouseNumber: [null],
        insuranceObjectBuiltYear: [1980],
        insuranceObjectSquare: ['80'],
        insuranceBaseAmount: [],
        kadastr: [null],
        floorCount: [17],
        monthlyPayment: [null],
        ownershipLessThreeYears: [true],
        ownershipRestrictDoc: [true],
        hasGas: [false],
        withWood: [false],
        loanAgreementNumber: [null],
        loanAgreementEndDate: [null],
        hasIllegalRedevelopment: [false],
        mortgageObject: [false],
        ownershipConfirmDoc: [null],
        roomsCount: [null],
        floor: [null],
        hasOwnership: [false]
      }),
      insurer: this.fb.group({
        insurerFirstName: [null, ],
        insurerLastName: [null, ],
        insurerMiddleName: [null],
        insurerBirthDate: [null, ],
        passportLicense: [null, {updateOn: 'change'}, ],
        insurerPassportDate: [null, {updateOn: 'change'}, ],
        insurerCity: [null, {updateOn: 'change'}, ],
        insurerAddressDaData: [null, {updateOn: 'change'}],
        insurerIsJuridical: [false, {updateOn: 'change'}],
      }),
      contacts: this.fb.group({
        email: ['', {updateOn: 'change'}, ],
        phone: ['', {updateOn: 'change'}, ],
        smsCode: [{value: null, disabled: true}, ],
      }),
    });

  }

  public validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsUntouched({onlySelf: true});
        control.markAsPristine();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // Проверяем динамические поля
  public checkValidationDynamicFields(res: IMortgageProductDetailsResponce): void {
    const insuranceObjectFormGroup: FormGroup = this.form.get('insuranceObject') as FormGroup;
    const borrowerFormGroup: FormGroup = this.form.get('borrower') as FormGroup;
    const mortgage: FormGroup = this.form.get('mortgage') as FormGroup;

    const {
      hasIllegalRedevelopment,
      hasGas,
      hasOwnership,
      insuranceBaseAmount,
      kadastr,
      loanAgreementEndDate,
      loanAgreementNumber,
      monthlyPayment,
      mortgageObject,
      ownershipConfirmDoc,
      ownershipLessThreeYears,
      ownershipRestrictDoc,
      withWood,
      insuranceObjectSquare,
      insuranceObjectBuiltYear,
      floorCount,
      roomsCount,
      floor
    } = insuranceObjectFormGroup.controls;

    const {
      weight,
      height,
      highPressure,
      lowPressure,
      borrowerBirthPlace,
      borrowerProfession,
    } = borrowerFormGroup.controls;

    const {
      mortgageBeginEndDate,
      withRefusalRate,
      creditRate,
      loanIssueAddress
    } = mortgage.controls;

    const fields: IFields | undefined = res.value?.productRestrictions?.fields;

    if (fields) {
      fields.hasGas ? hasGas.enable() : hasGas.disable();
      fields.withWood ? withWood.enable() : withWood.disable();

      // fields.kadastr ? kadastr.enable() : kadastr.disable();
      fields.hasOwnership ? hasOwnership.enable() : hasOwnership.disable();

      fields.insuranceBaseAmount ? insuranceBaseAmount.enable() : insuranceBaseAmount.disable();
      fields.insuranceBaseAmount ? insuranceBaseAmount.enable() : insuranceBaseAmount.disable();
      fields.hasIllegalRedevelopment ? hasIllegalRedevelopment.enable() : hasIllegalRedevelopment.disable();
      fields.loanAgreementEndDate ? mortgageBeginEndDate.enable() : mortgageBeginEndDate.disable();
      fields.loanAgreementNumber ? loanAgreementNumber.enable() : loanAgreementNumber.disable();
      fields.loanIssueAddress ? loanIssueAddress.enable() : loanIssueAddress.disable();
      fields.monthlyPayment ? monthlyPayment.enable() : monthlyPayment.disable();
      fields.mortgageObject ? mortgageObject.enable() : mortgageObject.disable();
      fields.ownershipConfirmDoc ? ownershipConfirmDoc.enable() : ownershipConfirmDoc.disable();
      fields.ownershipLessThreeYears ? ownershipLessThreeYears.enable() : ownershipLessThreeYears.disable();
      fields.ownershipRestrictDoc ? ownershipRestrictDoc.enable() : ownershipRestrictDoc.disable();

      // Контроллы заемщика
      fields.weight ? weight.enable() : weight.disable();
      fields.height ? height.enable() : height.disable();
      fields.highPressure ? highPressure.enable() : highPressure.disable();
      fields.lowPressure ? lowPressure.enable() : lowPressure.disable();
      fields.birthPlace ? borrowerBirthPlace.enable() : borrowerBirthPlace.disable();
      fields.profession ? borrowerProfession.enable() : borrowerProfession.disable();

      // fields.mortgageObject ? insuranceObjectSquare.enable() : insuranceObjectSquare.disable();
      fields.mortgageObject ? insuranceObjectBuiltYear.enable() : insuranceObjectBuiltYear.disable();
      fields.mortgageObject ? floorCount.enable() : floorCount.disable();

      fields.withRefusalRate ? withRefusalRate.enable() : withRefusalRate.disable();
      fields.creditRate ? creditRate.enable() : creditRate.disable();
      fields.roomsCount ? roomsCount.enable() : roomsCount.disable();
      // Этаж
      fields.floor ? floor.enable() : floor.disable();

    }

    const policyStartDateMaxOffsetDays = res.value?.policyStartDateMaxOffsetDays ? res.value?.policyStartDateMaxOffsetDays : 90;

    this.policyStartDateMaxOffsetDaysDate = moment().add(policyStartDateMaxOffsetDays, 'days').toDate();
    this.agreements = res.value?.agreements!;

  }

  // Сохраняем форму
  public saveFormToLocalStorage(): void {
    localStorage.setItem('mortgageFormValue', JSON.stringify(this.form.value));
  }

  public getFormFromLocalStorage(): any {
    const formDataFromLocalStorage = localStorage.getItem('mortgageFormValue');
    if (formDataFromLocalStorage) {
      const formData = JSON.parse(formDataFromLocalStorage);
      return formData;
    }
  }

  // Применяем данные в форму, взятые из кэша
  public setFormDataFromCache(formData: any): void {
    const insuranceObjectFormGroup: FormGroup = this.form.get('insuranceObject') as FormGroup;
    const borrowerFormGroup: FormGroup = this.form.get('borrower') as FormGroup;
    const contactsFormGroup: FormGroup = this.form.get('contacts') as FormGroup;
    const insurerFormGroup: FormGroup = this.form.get('insurer') as FormGroup;
    const mortgageFormGroup: FormGroup = this.form.get('mortgage') as FormGroup;
    const mortgagePolicyDateFormGroup: FormGroup = this.form.get('mortgagePolicyDate') as FormGroup;

    const {
      hasIllegalRedevelopment,
      hasOwnership,
      insuranceBaseAmount,
      kadastr,
      loanAgreementEndDate,
      loanAgreementNumber,
      loanIssueAddress,
      monthlyPayment,
      mortgageObject,
      ownershipConfirmDoc,
      ownershipLessThreeYears,
      ownershipRestrictDoc,
      withWood,
      insuranceObjectAddressSameAsInsurer,
      insuranceObjectAddress,
      insuranceHouseNumber,
      insuranceObjectAddressDaData,
      insuranceObjectBuiltYear,
      insuranceObjectSquare,
      floorCount,
    } = insuranceObjectFormGroup.controls;

    insuranceObjectAddressSameAsInsurer.setValue(formData?.mortgageLoan?.addressSameAsInsurer, { emitEvent: false });

    if (formData?.mortgageLoan?.addressSameAsInsurer) {
        const matchAddress = formData?.insured?.passport?.registrationAddressData?.fullAddressString?.match(/(.+), кв \d+/);
        if (matchAddress && matchAddress[1]) {
          const extractedAddress = matchAddress[1].trim();
          insuranceObjectAddress.setValue(extractedAddress, { emitEvent: false });
        }
        const flat = formData?.insured?.passport?.registrationAddressData?.flat;
        if (flat) {
          insuranceHouseNumber.setValue(flat, { emitEvent: false });
        }
      insuranceObjectAddressDaData.setValue(formData?.insured?.passport?.registrationAddressData, { emitEvent: false });
    } else {
      const matchAddress = formData?.mortgageLoan?.addressData?.fullAddressString?.match(/(.+), кв \d+/);
      if (matchAddress && matchAddress[1]) {
        const extractedAddress = matchAddress[1].trim();
        insuranceObjectAddress.setValue(extractedAddress);
      }
      const flat = formData?.mortgageLoan?.addressData?.flat;
      if (flat) {
        insuranceHouseNumber.setValue(flat, { emitEvent: false });
      }
      insuranceObjectAddressDaData.setValue(formData?.mortgageLoan?.addressData, { emitEvent: false });
    }


    insuranceObjectBuiltYear.setValue(formData?.mortgageLoan?.builtYear);
    floorCount.setValue(formData?.mortgageLoan?.floorCount);
    insuranceObjectSquare.setValue(formData?.mortgageLoan?.square, { emitEvent: false });
    kadastr.setValue(formData?.mortgageLoan?.kadastr, { emitEvent: false });
    insuranceBaseAmount.setValue(formData?.mortgageLoan?.baseAmount, { emitEvent: false });

    const {
      weight,
      height,
      highPressure,
      lowPressure,
    } = borrowerFormGroup.controls;

    const {
      mortgageStartDate,
      mortgageEndDate
    } = mortgagePolicyDateFormGroup.controls;
    if (formData?.policyStartDate) {
      mortgageStartDate.setValue(formData?.policyStartDate);
      mortgageEndDate.setValue(moment(formData?.policyStartDate, 'DD.MM.YYYY').add(1, 'year').format('DD.MM.YYYY'));
    }

    const {
      mortgageCreditRemain,
      mortgageWithoutCreditNumber,
      mortgageCreditNumber,
      mortgageBeginDate,
      mortgageBeginEndDate,
      withRefusalRate,
      creditRate
    } = mortgageFormGroup.controls;
    mortgageCreditRemain.setValue(formData?.creditRemain);
    mortgageWithoutCreditNumber.setValue(formData?.mortgageLoan?.withoutContractNumber);
    mortgageCreditNumber.setValue(formData?.mortgageLoan?.contractNumber);
    mortgageBeginDate.setValue(formData?.mortgageLoan?.mortgageStartDate);
    mortgageBeginEndDate.setValue(formData?.mortgageLoan?.mortgageEndDate);
    withRefusalRate.setValue(formData?.mortgageLoan?.withRefusalRate);

    if (formData?.mortgageLoan?.creditRate) {
      creditRate.setValue(formData?.mortgageLoan?.creditRate);
    }

    const {
      borrowerFirstName,
      borrowerLastName,
      borrowerMiddleName,
      borrowerBirthDate,
      borrowerPassportLicense,
      borrowerPassportDate,
      borrowerPassportIssuer,
      borrowerDepartmentCode,
      borrowerAddress,
      borrowerAddressDaData,
      borrowerHouseNumber,
    } = borrowerFormGroup.controls;
    borrowerFirstName.setValue(formData?.insured?.firstName);
    borrowerLastName.setValue(formData?.insured?.lastName);
    borrowerMiddleName.setValue(formData?.insured?.middleName);
    borrowerBirthDate.setValue(formData?.insured?.birthDate);
    borrowerPassportLicense.setValue(formData?.insured?.passport?.passportSeries + formData?.insured?.passport?.passportNumber);
    borrowerPassportDate.setValue(formData?.insured?.passport?.passportIssueDate);
    borrowerPassportIssuer.setValue(formData?.insured?.passport?.passportIssuer);
    borrowerDepartmentCode.setValue(formData?.insured?.passport?.departmentCode);
    borrowerAddress.setValue(formData?.insured?.passport?.registrationAddressData?.addressAsString, { emitEvent: false });
    borrowerAddressDaData.setValue(formData?.insured?.passport?.registrationAddressData?.addressAsString, { emitEvent: false });
    borrowerHouseNumber.setValue(formData?.insured?.passport?.registrationAddressData?.addressAsString, { emitEvent: false });

    const matchAddress = formData?.insured?.passport?.registrationAddressData?.fullAddressString?.match(/(.+), кв \d+/);
    if (matchAddress && matchAddress[1]) {
      const extractedAddress = matchAddress[1].trim();
      borrowerAddress.setValue(extractedAddress, { emitEvent: false });
    }
    const flat = formData?.insured?.passport?.registrationAddressData?.flat;
    if (flat) {
      borrowerHouseNumber.setValue(flat);
    }
    borrowerAddressDaData.setValue(formData?.insured?.passport?.registrationAddressData, { emitEvent: false });
    if (!formData?.mortgageLoan?.addressSameAsInsurer) {
      // const matchAddress = formData?.insured?.passport?.registrationAddressData?.fullAddressString?.match(/(.+), кв \d+/);
      // if (matchAddress && matchAddress[1]) {
      //   const extractedAddress = matchAddress[1].trim();
      //   borrowerAddress.setValue(extractedAddress, { emitEvent: false });
      // }
      // const flat = formData?.insured?.passport?.registrationAddressData?.flat;
      // if (flat) {
      //   borrowerHouseNumber.setValue(flat);
      // }
      // borrowerAddressDaData.setValue(formData?.insured?.passport?.registrationAddressData, { emitEvent: false });

    } else {
      // const matchAddress = formData?.mortgageLoan?.addressData?.fullAddressString?.match(/(.+), кв \d+/);
      // if (matchAddress && matchAddress[1]) {
      //   const extractedAddress = matchAddress[1].trim();
      //   borrowerAddress.setValue(extractedAddress, { emitEvent: false });
      // }
      // const flat = formData?.mortgageLoan?.addressData?.flat;
      // if (flat) {
      //   borrowerHouseNumber.setValue(flat, { emitEvent: false });
      // }
      // borrowerAddressDaData.setValue(formData?.mortgageLoan?.addressData, { emitEvent: false });
    }

    const {
      email,
      phone
    } = contactsFormGroup.controls;
    email.setValue(formData?.insured?.email);
    phone.setValue(formData?.insured?.phone?.slice(1));
  }

}
