import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from "rxjs";
import {finalize, switchMap, take} from "rxjs/operators";

// Сервисы
import {ContentService} from "../../../../core/services/content.service";
import {MortgageService} from "../../../../shared/services/mortgage.service";

// Интерфейсы
import {IFormFilter} from "../../../../shared/interface/form-filter.interface";
import {IOffer, IOffersResponce} from "../../../../shared/interface/offers.interface";
import {FormFilterService} from "../../../../shared/services/form-filter.service";
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";
import {OfflineFormComponent} from "../offline-form/offline-form.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AppService} from "../../../../core/services/app.service";
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../shared/services/form.service";
import {IMortgageProductsResponce} from "../../../../shared/interface/mortgage-products.interface";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(public readonly contentService: ContentService,
              public readonly mortgageService: MortgageService,
              private readonly formFilterService: FormFilterService,
              private readonly elementRef: ElementRef,
              private readonly ym: YandexMetrikaService,
              private readonly bsModalService: BsModalService,
              private readonly appService: AppService,
              public readonly formService: FormService) {
  }

  radius = 50;
  strokeWidth = 5;
  circumference = 2 * Math.PI * this.radius;
  progress = 0;
  timerDuration = 5000;
  // Метод для получения стиля dasharray, который зависит от прогресса
  getDashArray() {
    return `${this.progress} ${this.circumference}`;
  }

  startTimer() {
    const interval = 1000 / 60; // 60 FPS
    const step = (this.circumference / this.timerDuration) * interval;

    const intervalId = setInterval(() => {
      this.progress += step;
      if (this.progress >= this.circumference) {
        this.progress = this.circumference;
        clearInterval(intervalId);
      }
    }, interval);
  }


  // Индикатор загрузки
  public isLoading = false;
  public offers: any = [];
  // Подписка
  private subscriptions: Subscription = new Subscription();

  public paymentCountdown = '02:00';
  private countdownInterval: any;
  // Ссылка на модальное окно
  private bsModalRef?: BsModalRef;
  // Был произведен поиск аофферов после того как пришли из email
  private isChangeCalculateFromEmail = false;

  private checkArmApplication(): Observable<void> {
    if (!this.appService.isArmApplication)
      return of();

    return this.appService.armApplication$;
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.checkFormDataFromLocalStorage();
    this.startTimer();
  }

  public ngAfterViewInit(): void {
    this.ym.onYandexReachGoal('MAIN_GOAL');
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Получаем данные формы фильтра и ищем офферов
  public getFormValue(formFilterValue: IFormFilter): void {
    // Если мы заблочили контролы, остаток по кредиты, дату рождения и гендер, то берем их из параметрво url
    // Это сделано для партнера sber
    const value = formFilterValue;
    const params = this.appService.getUrlParams();
    if (params.insuredGender === 'Male' || params.insuredGender === 'Female') {
      value.insuredGender = params.insuredGender!;
    }
    if (params.contractNumber) {
      (this.formService.form.get('mortgage') as FormGroup)?.get('mortgageCreditNumber')?.setValue(params.contractNumber);
    }

    const formElement = this.elementRef.nativeElement.querySelector('#offers-list');
    formElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    this.ym.onYandexReachGoal('FINISH_FILTER_GOAL');

    this.mortgageService.offers = [];

    if (params.offerId && !this.isChangeCalculateFromEmail) {
      this.isLoading = true;
      this.startPaymentCountdown();
      this.isChangeCalculateFromEmail = true;
      this.calculateFromEmail(value);
    } else {
      const product = this.formFilterService.allMortgageProductList.find((item: any) => {
        return item.bankId === this.formFilterService.form.value.bankId
          && item.productType.toLowerCase() === this.formFilterService.form.value.product.toLowerCase()
          && item.mortgagePropertyTypes.find((mortgagePropertyType: string) => mortgagePropertyType.toLowerCase() === this.formFilterService.form.value.mortgagePropertyType.toLowerCase())
      });
      if (product) {
        this.isLoading = true;
        this.startPaymentCountdown();
        this.calculateDefault(value);
      }
    }
  }

  private calculateDefault(formValue: any): void {
    // Сохраняем данные форму в локальное хранилище
    // this.formService.saveFormToLocalStorage();
    // Запрос на получение предварительного расчета
    this.subscriptions.add(
      this.mortgageService.calculate(formValue)
        .pipe(finalize(() => {
          this.isLoading = false;
          this.stopTimer();
          clearInterval(this.countdownInterval);
          this.ym.onYandexReachGoal('FINISH_PREAPPROVE_GOAL');
        }))
        .subscribe((res: IOffersResponce) => {
          if (this.mortgageService?.offers && this.mortgageService?.offers?.length > 0) {
          } else {
            this.bsModalRef = this.bsModalService.show(OfflineFormComponent,
              { class: 'offlineForm gray modal-lg' });
          }
        })
    );
  }

  private calculateFromEmail(formValue: any): void {
    this.subscriptions.add(
      this.mortgageService.calculateFromEmail(formValue)
        .pipe(finalize(() => {
          this.isLoading = false;
          this.stopTimer();
          clearInterval(this.countdownInterval);
          this.ym.onYandexReachGoal('FINISH_PREAPPROVE_GOAL');
        }))
        .subscribe((res: IOffersResponce) => {
          if (this.mortgageService?.offers && this.mortgageService?.offers?.length > 0) {
            // this.offers = res.value?.offers.filter((offer: IOffer) => offer.status === 'PreApproved')
          } else {
            this.bsModalRef = this.bsModalService.show(OfflineFormComponent,
              { class: 'offlineForm gray modal-lg' });
          }
        })
    );
  }

  stopTimer(): void {
    this.paymentCountdown = "02:00";
  }

  // Счетчик
  public startPaymentCountdown() {

    // начинаем обратный отсчет
    this.countdownInterval = setInterval(() => {
      const timeParts = this.paymentCountdown.split(':');
      let minutes = +timeParts[0];
      let seconds = +timeParts[1];

      if (minutes === 0 && seconds === 0) {
        clearInterval(this.countdownInterval);
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  // Проверям данные в локальном хранилище
  private checkFormDataFromLocalStorage(): void {
    if (this.appService.isArmApplication)
      this.getFormDataFromARM();
    // Сохраняем форму в локал сторедж только если не пришли в форму по ссылке из почты
    else if (this.appService.srcUrlParam !== 'OffersRecivedEmail' && !this.appService.offerIdFromUrlParams && this.appService.dateFromCacheIsNull) {
      this.getFormDataFromLocalStorage();
    }

    this.showDefaultForm();
  }

  // Проверяем даныне формы в локальном хранилище
  private getFormDataFromARM(): void {

    // Получаем данные из локального хранилища, если они есть
    const formData = this.appService.armApplication?.application;

    if (formData) {
      this.formService.form.patchValue(formData);
      // (this.formService.form.get('borrower') as FormGroup)?.get('borrowerPassportIssuer')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.value);
      // (this.formService.form.get('borrower') as FormGroup)?.get('borrowerDepartmentCode')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.code);
    }
  }

    // Проверяем даныне формы в локальном хранилище
  private getFormDataFromLocalStorage(): void {

    // Получаем данные из локального хранилища, если они есть
    const formData = this.formService.getFormFromLocalStorage();

    if (formData) {
      this.formService.form.patchValue(formData);
      // (this.formService.form.get('borrower') as FormGroup)?.get('borrowerPassportIssuer')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.value);
      // (this.formService.form.get('borrower') as FormGroup)?.get('borrowerDepartmentCode')?.setValue(formData?.borrower?.borrowerPassportIssuerDadata?.code);
    }

    // Слушаем изменения значений в форме и сохраняем их в локальное хранилище
    // this.subscriptions.add(this.formService.form.valueChanges.subscribe((value) => {
      // this.formService.saveFormToLocalStorage();
    // }));
  }

  // Отображаем по умолчанию форму
  public showDefaultForm(): void {
    if (this.formService.isContactFormComplete
      && this.formService.isBorrowerFormComplete
      && this.formService.isMortgageFormComplete
      && this.formService.isPolicyFormComplete
      && this.formService.isInsuranceFormComplete) {
      this.formService.isInsuranceFormComplete = false;
    }
  }

}
